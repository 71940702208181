import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
  const location = useLocation();

  return (
    <nav className="navbar navbar-expand-lg navbar-dark tc-navbar-style1 section-padding-x">
      <div className="container-fluid content">
        <Link className="navbar-brand" to="/">
          <img src="/home1/assets/img/logobet.png" alt="" className="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className={location.pathname === "/" ? "nav-link active" : "nav-link"} aria-current="page" to="/">
                Ana Sayfa
              </Link>
            </li>
            <li className="nav-item">
              <Link className={location.pathname === "/studio" ? "nav-link active" : "nav-link"} to="/studio">
                Stüdyo
              </Link>
            </li>
            <li className="nav-item">
              <Link className={location.pathname === "/projects" ? "nav-link active" : "nav-link"} to="/projects">
                Projelerim
              </Link>
            </li>
            <li className="nav-item">
              <Link className={location.pathname === "/blogs" ? "nav-link active" : "nav-link"} to="/blogs">
                Blog
              </Link>
            </li>
            <li className="nav-item">
              <Link className={location.pathname === "/contact" ? "nav-link active" : "nav-link"} to="/contact">
                İletişim
              </Link>
            </li>
          </ul>
          <div className="nav-side">
            <Link to="#" className="icon ms-3">
              <span> EN </span>
            </Link>
            <Link to="#" className="icon ms-3">
              <span> FR </span>
            </Link>
            <Link to="#" className="icon ms-5 fsz-21">
              <span>
                <i className="la la-search"></i>{' '}
              </span>
            </Link>
            <Link to="#" className="icon ms-3 side_menu_btn fsz-21">
              <span>
                <i className="la la-grip-lines"></i>{' '}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
