import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home1 from './pages/home1';
import BlogDetails from './pages/posts/BlogDetails';
import Blogs from './pages/posts/Blogs';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home1 />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/first-blog" element={<BlogDetails />} />
      </Routes>
    </Router>
  );
}

export default App;
