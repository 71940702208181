import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../../components/home1/Navbar'
import PageHeader from '../../components/home1/small-components/PageHeader'
import BlogsList from '../../components/home1/small-components/BlogsList'

const Blogs = () => {
    React.useEffect(() => {
        document.querySelector('body').classList.add('index3')
    }, [])
    return (
        <>
            <Helmet>
                <link
                    rel="stylesheet"
                    type="text/css"
                    href="/home1/assets/css/home_1_style.css"
                />
                <script src="/common/assets/js/common_js.js"></script>
                <script src="/home1/assets/js/home_1_scripts.js"></script>
            </Helmet>

            <body style={{ background: "#181818" }} className="home-style1">
                <div>
                    <Navbar />
                    <PageHeader
                        title="Bloglarım"
                        fullPath={[
                            { id: 1, name: "Ana Sayfa", url: "/" },
                            { id: 2, name: "blogs", url: "/blogs" },
                        ]}
                    />
                    <BlogsList />
                </div>
            </body>

        </>
    );
}

export default Blogs;