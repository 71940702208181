import React from 'react';

function Testimonials() {
  return (
    <section className="tc-testimonials-style1">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <h6 className="fsz-18 text-uppercase lh-4">
              Müşterilerin gözünden  <br /> biz
            </h6>
            <div className="lg-icon color-orange1">
              <i className="la la-quote-right"></i>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="tc-clients-style1">
              <div className="clients-slider1">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="clients-card">
                      <div className="text fsz-40 fw-600 lh-2 js-splittext-lines">
                        “Hayal ettiğimizden bile daha güzel bir sonuç aldık! Tüm süreç boyunca bizimle sürekli iletişim halindeydiler ve her detayı titizlikle ele aldılar. 3D tasarımlar sayesinde projemizin her aşamasını önceden görebildik. İşin sonunda, tam da istediğimiz gibi modern, ferah ve kullanışlı bir yaşam alanına kavuştuk. Ekibe sonsuz teşekkürler!”
                      </div>
                      <div className="author">
                        <div className="au-img">
                          <img
                            src="/home1/assets/img/team/team4.jpg"
                            alt=""
                            className="img-cover"
                          />
                        </div>
                        <div className="au-inf">
                          <h6 className="text-capitalize mb-2 fsz-16 fw-bold">
                            Ahmet Y.
                          </h6>
                          <p className="text-capitalize fsz-14 color-666">
                            Avukat
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="clients-card">
                      <div className="text fsz-40 fw-600 lh-2 js-splittext-lines">
                        “Ofisimizde hem şıklığı hem de işlevselliği aynı anda yakalamak istiyorduk ve bu ekip tam da bunu başardı. Tasarım aşamasında sundukları çözümler, mekânın potansiyelini ortaya çıkardı. Ekibin profesyonelliği ve detaylara verdiği önem gerçekten etkileyiciydi. İş teslim süreci de planlandığı gibi sorunsuz gerçekleşti. Kesinlikle tavsiye ederim!”
                      </div>
                      <div className="author">
                        <div className="au-img">
                          <img
                            src="/home1/assets/img/team/team5.jpg"
                            alt=""
                            className="img-cover"
                          />
                        </div>
                        <div className="au-inf">
                          <h6 className="text-capitalize mb-2 fsz-16 fw-bold">
                            Mert İ.
                          </h6>
                          <p className="text-capitalize fsz-14 color-666">
                            Ticari Proje Sahibi
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="clients-card">
                      <div className="text fsz-40 fw-600 lh-2 js-splittext-lines">
                        “Kafemizin tasarımı hem müşterilerimizi rahat ettirmeli hem de görsel olarak dikkat çekici olmalıydı. Bu ekip tüm beklentilerimizi fazlasıyla karşıladı! Mekanın enerjisini doğru yansıtan, kullanışlı ve estetik bir alan yarattılar. Misafirlerimiz artık sadece kahvelerimiz için değil, mekânımızın atmosferi için de bizi tercih ediyor. Harikasınız!”
                      </div>
                      <div className="author">
                        <div className="au-img">
                          <img
                            src="/home1/assets/img/team/team6.jpg"
                            alt=""
                            className="img-cover"
                          />
                        </div>
                        <div className="au-inf">
                          <h6 className="text-capitalize mb-2 fsz-16 fw-bold">
                            Eşref T.
                          </h6>
                          <p className="text-capitalize fsz-14 color-666">
                            Kafe Sahibi
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slider-controls">
                  <div className="swiper-button-prev"></div>
                  <div className="swiper-pagination"></div>
                  <div className="swiper-button-next"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="marq-slider">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <a href="#"> Yaratıcı </a>
          </div>
          <div className="swiper-slide">
            <a href="#"> Esnek </a>
          </div>
          <div className="swiper-slide">
            <a href="#"> Adanmış </a>
          </div>
        </div>
      </div>
      <img src="/home1/assets/img/c_line3.png" alt="" className="c-line" />
    </section>
  );
}

export default Testimonials;
