import React from "react";
import { Link, useLocation } from "react-router-dom";

const PageHeader = ({ title, fullPath, image }) => {
    const location = useLocation();

    return (
        <header
            className="pages-header bg-img valign"
            style={{
                backgroundImage: `url(${image || "/personal_architect/img/house.jpg"})`,
                objectFit: "cover",
            }}
            data-overlay-dark="5"
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="cont text-center">
                            <h1 className="text-white">{title}</h1>
                            <div className="path">
                                {fullPath.map((item, index) => (
                                    <React.Fragment key={item.id}>
                                        <Link
                                            to={item.url}
                                            className={location.pathname === item.url ? "active" : ""}
                                        >
                                            {item.name}
                                        </Link>
                                        {index !== fullPath.length - 1 && <span>/</span>}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default PageHeader;