import React from "react";
import PageHeader from "../../components/home1/small-components/PageHeader";
import PostDetails from "../../components/home1/small-components/Post1";
import Navbar from "../../components/home1/Navbar";
import { Helmet } from "react-helmet";
/* import Loader from "../../components/common/Loader"; 
import Menu from "../../components/home1/Menu"; */

const BlogDetails = () => {
    React.useEffect(() => {
        document.querySelector('body').classList.add('index3')
    }, [])
    return (
        <>
            <Helmet>
                <link
                    rel="stylesheet"
                    type="text/css"
                    href="/home1/assets/css/home_1_style.css"
                />
                <script src="/common/assets/js/common_js.js"></script>
                <script src="/home1/assets/js/home_1_scripts.js"></script>
            </Helmet>
            <body style={{ background: "#181818" }} className="home-style1">
              {/*   <Loader /> */}
              {/*   <Menu /> */}
                <div >
                    <Navbar />
                    <PageHeader
                        title="Blog Detayı"
                        fullPath={[
                            { id: 1, name: "home", url: "/" },
                            { id: 2, name: "bloglar", url: "/blogs" },
                            { id: 3, name: "Yayın Detayı", url: "/first-blog" },
                        ]}

                    />
                    <PostDetails />
                </div>
            </body>

        </>
    );
};

export default BlogDetails;