import React from 'react';

function Experience() {
  return (
    <section className="tc-experience-style1 section-padding-x">
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-2">
            <div className="exp-num mb-100 wow zoomIn" data-wow-delay="0.3s">
              <h2 className="num"> 7 </h2>
              <h5 className="fsz-18 text-uppercase">
                Yıllık <br /> deneyim
              </h5>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="img wow">
              <img
                src="/home1/assets/img/exp.png"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="info wow fadeInUp" data-wow-delay="0.3s">
              <h3 className="fsz-45 fw-600 mb-30"> Kaliteye ve Yeniliğe Adanmışlık </h3>
              <div className="text fsz-15 color-666">
                Farklı ölçeklerde projelerle edindiğimiz deneyimler, tasarım anlayışımıza derinlik ve çeşitlilik katmaktadır. Konut, ticari ve kamusal alanlarda gerçekleştirdiğimiz projelerde, estetik, işlevsellik ve sürdürülebilirlik ilkelerini bir araya getirerek mekânlara özgün bir kimlik kazandırıyoruz. Her projede kullanıcı odaklı ve bağlama duyarlı tasarımlar üretmeye öncelik veriyoruz.
              </div>
              <a
                href="#"
                className="butn rounded-pill mt-50 hover-bg-black bg-white"
              >
                <span>
                  Stüdyomuz <i className="small ms-1 ti-arrow-top-right"></i>
                </span>
              </a>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="rotate-txt justify-content-lg-end">
              <ul>
                <li>
                  <a href="#"> info@betulakarchitecture.com.tr </a>
                </li>
                <li>
                  <a href="#">(536) 224 14 46</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <img src="/home1/assets/img/c_line.png" alt="" className="c-line wow" />
    </section>
  );
}

export default Experience;
