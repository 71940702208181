import React from "react";
import Blog1Data from "../../../data/home1/blogs/blogData.json";
import { Link } from "react-router-dom";

const BlogsList = () => {
    return (
        <section className="blog-pg section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="posts">
                            {Blog1Data.map((item) => (
                                <div className="item mb-80" key={item.id}>
                                    <div className="img op-10">
                                        <Link to="/fırst-blog">
                                            <img
                                                src={item.image}
                                                alt=""
                                                className="thumparallax"
                                            />
                                        </Link>
                                    </div>
                                    <div className="content">
                                        <div className="date">
                                            <h5 className="text-white">
                                                <Link to="/first-blog" style={{ display: "flex", flexDirection: "column" }}>
                                                    <span className="num">{item.date2[1]}</span>
                                                    <span>{item.date2[0]}</span>
                                                </Link>
                                            </h5>
                                        </div>
                                        <div className="cont">
                                            <div className="tags">
                                                <Link to="#">Web</Link>
                                                <Link to="#">Mimarlık</Link>
                                                <Link to="#">Tasarım</Link>
                                            </div>
                                            <h4 className="title">
                                                <Link to="/first-blog" className="text-white">
                                                    Bina tasarım aşamalarımız
                                                </Link>
                                            </h4>
                                            <p style={{ lineHeight: "2", color: "#9f9f9f", fontSize: 15 }}>
                                                Başarı tesadüf değildir
                                            </p>
                                            <Link className="more text-white" to="/first-blog" >
                                                Daha fazla
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {/*                 <div className="pagination">
                                    <span className="active">
                                        <Link to="/">1</Link>
                                    </span>
                                    <span>
                                        <Link to="/">2</Link>
                                    </span>
                                    <span>
                                        <Link to="/">
                                            <i className="fas fa-angle-right"></i>
                                        </Link>
                                    </span>
                                </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlogsList;
