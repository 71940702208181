import React from "react";
import { Link } from "react-router-dom";

const PostDetails = () => {
    return (
        <>
            <section className="blog-pg single section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                            <div className="post">
                                <div className="title-head">
                                    <h2>Blog Başlığı</h2>
                                    <div className="info">
                                        <p style={{ color: "#9f9f9f", fontSize: "15", fontWeight: "400", lineHeight: "2", margin: "0" }}>
                                            <Link to="#">Betül Ak</Link>/
                                            <Link to="#">Ağustos 6 , 2024</Link>/
                                            <Link to="#">Mimar</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="img main-img">
                                    <img
                                        src="/personal_architect/img/kitchen.jpg"
                                        alt=""
                                        className="thumparallax"
                                    />
                                </div>
                                <div className="content pt-20">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-10">
                                            <div className="cont">
                                                <div className="spacial">
                                                    <p>
                                                        Bir mimar olarak inanıyorum ki her tasarım bir hikayeyle başlar. Yaratılan mekanlar, sadece duvarlardan ve tavanlardan ibaret değildir; içinde yaşayan insanların ruhunu yansıtan canlı alanlardır. Kağıda çizilen her çizgi, yaşamları şekillendirme, duyguları uyandırma ve kalıcı bir miras bırakma potansiyeline sahiptir.

                                                    </p>
                                                </div>
                                                <p style={{ color: "#9f9f9f" }}>
                                                    Bir mekanı tasarlamak, ışık, doku ve işlevselliğin iç içe geçtiği bir dokuma halısını örmek gibidir. Sabır, vizyon ve insan ihtiyaçlarını derinlemesine anlama gerektirir. İlhamın nehir gibi aktığı günler olur, bazen de şüphe insana musallat olur. Ama öğrendiğim bir şey var ki her zorluk, yaratıcılığın sınırlarını zorlamak için bir fırsattır.

                                                </p>

                                                <h6 style={{ fontSize: "18px", color: "#fff" }}>- Her detay önemlidir.</h6>

                                                <p style={{ color: "#9f9f9f" }}>
                                                    Mimarlıkta her detay önemlidir. Bir duvarın dokusundan doğal ışığın bir pencereden süzülüşüne kadar her öğe, bütünsel deneyimin bir parçasıdır. Biz mimarlar, hikaye anlatıcılarıyız ve anlatım aracımız mekandır. Değişime, büyümeye ve insan bağlantısına yer açacak şekilde tasarlamalıyız.

                                                </p>

                                                <ul style={{ marginBottom: "1rem", marginTop: 0 }}>
                                                    <li style={{ margin: "10px 0", color: "#fff" }}>
                                                        <span style={{ marginRight: "10", fontWeight: "500", fontSize: "13px" }}>01</span> Her malzemenin bir hikayesi vardır.
                                                    </li>
                                                    <li style={{ margin: "10px 0", color: "#fff" }}>
                                                        <span>02</span> Işık, her mekanın ruhudur.
                                                    </li>
                                                    <li style={{ margin: "10px 0", color: "#fff" }}>
                                                        <span>03</span> İşlevsellik, güzellikle buluşmalıdır.

                                                    </li>
                                                    <li style={{ margin: "10px 0", color: "#fff" }}>
                                                        <span>04</span> Her zorluk, gizli bir fırsattır.
                                                    </li>
                                                    <li style={{ margin: "10px 0", color: "#fff" }}>
                                                        <span>05</span> Mimarlık, sadece mekanlar için değil, insanlar içindir.
                                                    </li>
                                                </ul>

                                                <div className="quotes text-center">
                                                    <p style={{ color: "#9f9f9f" }}>
                                                        "En iyi mimarlık, bizi sadece barındırmakla kalmaz; dünyayı farklı görmemizi sağlar."
                                                    </p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="mb-10">
                                                            <img src="/personal_architect/img/kitchen.jpg" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-10">
                                                            <img src="/personal_architect/img/kitchen.jpg" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p style={{ color: "#9f9f9f" }}>
                                                    Mimarlar olarak her zaman yaratıcılığı pratiklikle dengelemeye çalışıyoruz. Tasarım süreci nadiren düz bir çizgide ilerler — yeni fikirler ortaya çıktıkça kıvrılır, döner ve evrilir. Bu süreçte esnek, açık fikirli ve değişime hazır olmalıyız. Ancak bu akışkanlık içinde değişmeyen bir şey var: insan deneyimi. Çizilen her çizgi, seçilen her malzeme ve yaratılan her mekan, içinde yaşayacak, çalışacak ve hayal kuracak insanlara hizmet etmelidir. İşte bu, mimarlığı yaşayan, nefes alan bir sanat formuna dönüştüren şeydir — ve bu yüzden mimarlığı seviyorum.
                                                </p>
                                                <div className="share-info">
                                                    <div className="social">
                                                        <Link to="/">
                                                            <i className="fab fa-facebook-f text-white"></i>
                                                        </Link>
                                                        <Link to="/">
                                                            <i className="fab fa-twitter text-white"></i>
                                                        </Link>
                                                    </div>
                                                    <div className="tags">
                                                        <Link to="/" className="text-white">Web</Link>,
                                                        <Link to="/" className="text-white">Mimarlik</Link>,
                                                        <Link to="/" className="text-white">Tasarım</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="author">
                                                <div className="author-img">
                                                    <img src="/personal_architect/img/personel.jpg" alt="" />
                                                </div>
                                                <div className="info">
                                                    <h6 className="text-white">
                                                        <span>yazar :</span> Betül AK
                                                    </h6>
                                                    <p style={{ color: "#9f9f9f" }}>
                                                        Ben Betül Ak, İstanbul, TR merkezli bir mimarım. Seçici ve sofistike uluslararası müşterilerime yönelik, tamamen özel mekan tasarımı hizmetleri sunuyorum.
                                                    </p>
                                                    <div className="social">
                                                        <Link to="#">
                                                            <i className="fab fa-facebook-f text-white"></i>
                                                        </Link>
                                                        <Link to="#">
                                                            <i className="fab fa-twitter text-white"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*                                 <div className="pagination">
                                    <span>
                                        <Link to="#">Prev Post</Link>
                                    </span>
                                    <span className="icon">
                                        <Link to="#">
                                            <a>
                                                <i className="fas fa-th-large"></i>
                                            </a>
                                        </Link>
                                    </span>
                                    <span className="text-right">
                                        <Link to="#">Next Post</Link>
                                    </span>
                                </div> */}

                                {/*           <div className="comments-area">
                                    <h5>Comments :</h5>
                                    <div className="item">
                                        <div className="comment-img">
                                            <img src="/assets/img/blog/01.jpg" alt="" />
                                        </div>
                                        <div className="info">
                                            <h6>
                                                Jorden Griffin<span> 6 Aug 2022</span>
                                            </h6>
                                            <span className="replay">
                                                <Link to="#">
                                                    <a>
                                                        Replay <i className="fas fa-reply"></i>
                                                    </a>
                                                </Link>
                                            </span>
                                            <p>
                                                the main component of a healthy environment for self
                                                esteem is that it needs be nurturing. The main compont
                                                of a healthy environment.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item relped">
                                        <div className="comment-img">
                                            <img src="/assets/img/blog/01.jpg" alt="" />
                                        </div>
                                        <div className="info">
                                            <h6>
                                                Jorden Griffin<span> 6 Aug 2022</span>
                                            </h6>
                                            <span className="replay">
                                                <Link to="#">
                                                    <a>
                                                        Replay <i className="fas fa-reply"></i>
                                                    </a>
                                                </Link>
                                            </span>
                                            <p>
                                                the main component of a healthy environment for self
                                                esteem is that it needs be nurturing. The main compont
                                                of a healthy environment.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="comment-img">
                                            <img src="/assets/img/blog/01.jpg" alt="" />
                                        </div>
                                        <div className="info">
                                            <h6>
                                                Jorden Griffin<span> 6 Aug 2022</span>
                                            </h6>
                                            <span className="replay">
                                                <Link to="#">
                                                    <a>
                                                        Replay <i className="fas fa-reply"></i>
                                                    </a>
                                                </Link>
                                            </span>
                                            <p>
                                                the main component of a healthy environment for self
                                                esteem is that it needs be nurturing. The main compont
                                                of a healthy environment.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="comment-form">
                                    <h5>Add Comment :</h5>
                                    <div className="form">
                                        <form action="">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <textarea placeholder="Your Comment"></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="text" placeholder="Your Name" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="email" placeholder="Your Email" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <button className="btn-curve btn-blc btn-lg">
                                                            <span>Submit</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PostDetails;
