import React from 'react';
import { Link } from 'react-router-dom';

function Team() {
  return (
    <header className="tc-header-style4">
      <div className="container">
        <div className="info">
          <div className="row">
            <div className="col-lg-4">
              <div
                className="img mb-4 mb-lg-0 wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                <img
                  src="/personal_architect/img/personel.jpg"
                  alt="Betül Ak"
                  className="img-cover rounded"
                />
              </div>
            </div>
            <div className="col-lg-5 wow fadeInUp slow" data-wow-delay="0.4s">
              <div className="text fsz-24 mb-30">
                Ben Betül Ak, İstanbul, TR merkezli bir mimarım. Seçici ve sofistike uluslararası müşterilerime yönelik, tamamen özel mekan tasarımı hizmetleri sunuyorum.
              </div>
              <div className="text fsz-24 mb-80">
                Projelerimdeki felsefe, çağdaş ve zamansız bir lüks atmosfer yaratmaktır.
              </div>
            </div>
            <div className="col-lg-2 offset-lg-1">
              <div className="social wow fadeInUp slow" data-wow-delay="0.6s">
                <p className="fsz-12 text-uppercase color-999 mb-30">
                  {' '}
                  Sosyal{' '}
                </p>
                <ul className="text-uppercase fsz-18 lh-6">
                  <li>
                    <Link target="_blank" to={"https://www.instagram.com/betulakarchitecture/"}>
                      Instagram </Link>{' '}
                  </li>
                  <li>
                    <Link target="_blank" to="https://www.linkedin.com/in/bet%C3%BCl-ak-133385235/">
                      Linked </Link>{' '}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Team;
